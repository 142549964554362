import { Checkbox } from '@/components/ui/checkbox';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { DatetimeInput, Input } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Textarea } from '@/components/ui/textarea';
import {
  type DataSchema,
  fieldFromScope,
  isUISchemaControl,
  isUISchemaGroup,
  isUISchemaLayout,
  type UISchema,
} from '@/lib/incidentType';
import { cn } from '@/lib/utils';
import { type Control } from 'react-hook-form';

type NewIncidentFormInputBuilderProps = {
  readonly control: Control<
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [x: string]: any;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >;
  readonly dataSchema: DataSchema;
  readonly path?: string;
  readonly schema: UISchema;
};

const NewIncidentFormInputBuilder = ({
  control,
  dataSchema,
  path = '#',
  schema,
}: NewIncidentFormInputBuilderProps) => {
  if (isUISchemaLayout(schema)) {
    return (
      <div className="space-y-4">
        {schema.elements.map((item, index) => (
          <NewIncidentFormInputBuilder
            control={control}
            dataSchema={dataSchema}
            key={path + '-' + index.toString()}
            path={path + '-' + index.toString()}
            schema={item}
          />
        ))}
      </div>
    );
  } else if (isUISchemaGroup(schema)) {
    if (schema.options?.controlName) {
      return (
        <FormField
          control={control}
          name={schema.options.controlName}
          render={() => (
            <div className="grid items-start grid-cols-12 pb-4">
              <FormLabel
                className={
                  schema.options?.inline ? 'col-span-12' : 'col-span-3'
                }
              >
                {schema.label}
                {schema.options?.required && (
                  <span className="ml-1 text-red-500">*</span>
                )}
              </FormLabel>
              <div
                className={
                  schema.options?.inline
                    ? 'pt-4 pl-4 col-span-12 space-y-4'
                    : 'col-span-9 space-y-4'
                }
              >
                {schema.elements.map((item, index) => (
                  <NewIncidentFormInputBuilder
                    control={control}
                    dataSchema={dataSchema}
                    key={path + '-' + index.toString()}
                    path={path + '-' + index.toString()}
                    schema={item}
                  />
                ))}
              </div>

              <FormMessage className="mt-2 col-start-4 col-span-9" />
            </div>
          )}
        />
      );
    } else {
      return (
        <div className="grid grid-cols-12 items-start pb-4">
          <FormLabel
            className={schema.options?.inline ? 'col-span-12' : 'col-span-3'}
          >
            {schema.label}
            {schema.options?.required && (
              <span className="ml-1 text-red-500">*</span>
            )}
          </FormLabel>
          <div
            className={
              schema.options?.inline
                ? 'pt-4 pl-4 col-span-12 space-y-4'
                : 'col-span-9 space-y-4'
            }
          >
            {schema.elements.map((item, index) => (
              <NewIncidentFormInputBuilder
                control={control}
                dataSchema={dataSchema}
                key={path + '-' + index.toString()}
                path={path + '-' + index.toString()}
                schema={item}
              />
            ))}
          </div>
        </div>
      );
    }
  } else if (isUISchemaControl(schema)) {
    const dataField = fieldFromScope(schema.scope, dataSchema);

    if (dataField.type === 'string') {
      return (
        <FormField
          control={control}
          name={dataField.name}
          render={({ field }) => (
            <FormItem
              className={cn(
                'space-y-0 grid grid-cols-12 items-center',
                schema.options?.format === 'radio' && 'items-start',
              )}
            >
              <FormLabel className="col-span-3">
                {schema.label}
                {dataField.isRequired && (
                  <span className="ml-1 text-red-500">*</span>
                )}
              </FormLabel>
              <FormControl className="col-span-9">
                {schema.options?.format === 'radio' ? (
                  <RadioGroup
                    className="flex flex-col space-y-1"
                    defaultValue={field.value}
                    onValueChange={(value) => field.onChange(value)}
                  >
                    {dataField.oneOf?.map((item) => (
                      <FormItem
                        className="flex items-center space-x-3 space-y-0"
                        key={item.const}
                      >
                        <FormControl>
                          <RadioGroupItem value={item.const} />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {item.title}
                        </FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                ) : dataField.format === 'date' ? (
                  <DatetimeInput
                    onValueChange={(value) => field.onChange(value)}
                    type="date"
                    value={field.value}
                  />
                ) : dataField.format === 'date-time' ? (
                  <DatetimeInput
                    onValueChange={(value) => field.onChange(value)}
                    type="datetime-local"
                    value={field.value}
                  />
                ) : schema.options?.multiline ? (
                  <Textarea {...field} />
                ) : (
                  <Input {...field} />
                )}
              </FormControl>
              <FormMessage className="mt-2 col-start-4 col-span-9" />
            </FormItem>
          )}
        />
      );
    }

    if (dataField.type === 'boolean') {
      return (
        <FormField
          control={control}
          name={dataField.name}
          render={({ field }) => (
            <FormItem className="flex flex-row items-start gap-2 space-y-0">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    field.onChange(checked);
                  }}
                />
              </FormControl>
              <FormLabel className="font-normal">
                {schema.label}
                {dataField.isRequired && (
                  <span className="ml-1 text-red-500">*</span>
                )}
              </FormLabel>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    }

    if (dataField.type === 'number') {
      return (
        <FormField
          control={control}
          name={dataField.name}
          render={({ field }) => {
            return (
              <FormItem className="space-y-0 grid grid-cols-12 items-center">
                <FormLabel className="col-span-3">
                  {schema.label}
                  {dataField.isRequired && (
                    <span className="ml-1 text-red-500">*</span>
                  )}
                </FormLabel>
                <FormControl className="col-span-9">
                  <Input
                    {...field}
                    onChange={(event) =>
                      field.onChange(Number.parseFloat(event.target.value))
                    }
                    type="number"
                    value={field.value?.toString()}
                  />
                </FormControl>
                <FormMessage className="col-span-full" />
              </FormItem>
            );
          }}
        />
      );
    }
  }

  return null;
};

export { NewIncidentFormInputBuilder };
